import { config } from './config'

interface IImageProxyOptions {
  height?: number
  width?: number
  quality?: number
  resize?: 'auto' | 'fit' | 'fill'
  wp?: boolean
}

function imageProxy(filename: string, options: IImageProxyOptions = {}) {
  if (!filename) return ''
  if (options?.wp && process.env.NODE_ENV === 'development') return filename

  const { height = 0, width = 0, quality = 80, resize = 'auto', wp } = options

  let uri = !wp
    ? `${config.publicRuntimeConfig.cdnBucket}/${filename}`
    : filename.replace('https://storage.googleapis.com/', '')

  return `${config.publicRuntimeConfig.cdnUrl}/insecure/q:${quality}/rs:${resize}:${width}:${height}:1:0/plain/${uri}`
}

export default imageProxy
