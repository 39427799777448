import React from 'react'
import {
  Flex,
  FlexProps,
  Container,
  Link,
  Text,
  Stack,
  Graphic,
  Box,
  Divider,
  Heading,
  Image,
  Button,
  useToast,
} from '@thirstycamel/ui'

import { useActions, useStore } from '../../store/hooks'
import { useUser } from '../../hooks/useUser'
import { useRouter } from 'next/dist/client/router'
import { useEffect } from 'react'

const categories = [
  { name: 'Beer', slug: 'beer' },
  { name: 'White Wine', slug: 'white-wine' },
  { name: 'Red Wine', slug: 'red-wine' },
  { name: 'Spirits', slug: 'spirits' },
  { name: 'Premix', slug: 'premix' },
  { name: 'Specials', slug: 'specials' },
  { name: 'Cider', slug: 'cider' },
  { name: 'Rosé', slug: 'rose' },
]

export interface MainFooterProps extends FlexProps {}

export const MainFooter = React.memo(({ ...restProps }: MainFooterProps) => {
  const { isCamelCard } = useUser()
  const { showModal } = useActions(actions => actions.modal)
  const toast = useToast()
  const router = useRouter()

  const handleShowReferFriendModal = () => {
    showModal({
      name: 'HUMP_CLUB_REFER_FRIEND',
      props: {
        onConfirm: (friendName: string) => {
          toast({
            status: 'success',
            title: `${friendName} has been invited! You will receive your reward after they sign up.`,
          })
        },
      },
    })
  }

  useEffect(() => {
    /* If the refer a friend param is present in the URL, show the refer a friend modal */
    if (router.query?.referfriend) {
      handleShowReferFriendModal()
    }
  }, [router?.query?.referfriend])

  const pageLinks: {
    label: string
    href?: string
    hrefAs?: string
    onClick?: () => void
  }[] = [
    { label: 'About', href: '/page/[slug]', hrefAs: '/page/about' },
    { label: 'Store Locator', href: '/store-locator' },
    { label: 'Contact Us', href: '/contact-us' },
    isCamelCard
      ? { label: 'Camel Card', href: '/page/[slug]', hrefAs: '/page/camel-card' }
      : { label: 'Hump Club', href: '/page/[slug]', hrefAs: '/page/hump-club' },
    { label: 'RSA', href: '/page/[slug]', hrefAs: '/page/rsa' },
    { label: 'Competitions', href: '/competitions' },
    {
      label: 'Refer a Friend',
      onClick: handleShowReferFriendModal,
    },
    { label: 'FAQs', href: '/page/[slug]', hrefAs: '/page/faqs' },
  ]

  console.log('router', router)

  return (
    <Flex
      align="center"
      borderTop="1px"
      borderColor="gray.200"
      bg="gray.50"
      textAlign={['center', , 'left']}
      direction="column"
      {...restProps}
    >
      <Container
        size="sm"
        py={10}
        display="flex"
        flexDirection={['column-reverse', , 'row']}
        alignItems={['center', , 'flex-start']}
        position="relative"
        overflow="hidden"
      >
        <Stack spacing={8}>
          <Flex maxWidth={[, , '30rem', '35rem']} display={['none', , 'flex']}>
            <Flex wrap="wrap">
              {categories.map(category => (
                <Link
                  href="/[group]"
                  hrefAs={`/${category.slug}`}
                  textTransform="uppercase"
                  fontFamily="heading"
                  fontWeight="bold"
                  fontSize="lg"
                  color="pink.500"
                  py={2}
                  flexBasis="50%"
                >
                  {category.name}
                </Link>
              ))}
            </Flex>

            <Flex wrap="wrap">
              {pageLinks.map(link => {
                if (link.href) {
                  return (
                    <Link
                      href={link.href}
                      hrefAs={link.hrefAs}
                      fontSize="sm"
                      color="pink.500"
                      py={2}
                      flexBasis="50%"
                    >
                      {link.label}
                    </Link>
                  )
                }

                return (
                  <Button
                    onClick={link.onClick}
                    variant="link"
                    variantColor="pink"
                    textTransform="none"
                    fontSize="sm"
                    fontFamily="unset"
                    fontWeight="medium"
                    color="pink.500"
                    py={2}
                    flexBasis="50%"
                    justifyContent="flex-start"
                  >
                    Refer a friend
                  </Button>
                )
              })}
            </Flex>
          </Flex>

          <Stack spacing={4}>
            <Text fontSize="sm">
              &copy; Copyright Thirsty Camel Bottleshops {new Date().getFullYear()}
              <Box as="br" display={[, , 'none']} />
              <Box as="span" display={['none', , 'inline']}>
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </Box>
              <Link href="/page/[slug]" hrefAs="/page/privacy-policy" color="pink.500">
                Privacy Policy
              </Link>
              &nbsp;&nbsp;
              <Box as="span" display={['none', , 'inline']}>
                |&nbsp;&nbsp;
              </Box>
              <Link href="/page/[slug]" hrefAs="/page/terms-conditions" color="pink.500">
                Terms & Conditions
              </Link>
            </Text>
          </Stack>
        </Stack>

        <Stack direction="row" mt={2} mx={4} mb={[6, , 2]}>
          <Link href="/page/[slug]" hrefAs="/page/rsa">
            <Image
              src="/static/images/drink-responsibly-badge.png"
              size={['64px', '80px', '64px']}
            />
          </Link>
        </Stack>

        <Graphic
          name="camel"
          display={['none', , 'block']}
          size={[, , '240px', '300px']}
          opacity={0.2}
          position="absolute"
          right={0}
          bottom={[, , '-10%', '-20%']}
        />
      </Container>

      <Divider />

      <Container
        size="sm"
        py={10}
        display="flex"
        flexDirection={['column-reverse', , 'row']}
        alignItems={['center', , 'flex-start']}
        position="relative"
        overflow="hidden"
      >
        <Stack>
          <Heading fontSize="sm">
            Thirsty Camel bottleshops supports the Responsible Service of Alcohol.&nbsp;
          </Heading>

          <Text fontSize="sm">
            {/* <Box as="br" display={[, , 'none']} /> */}
            <Link href="/page/[slug]" hrefAs="/page/rsa" color="pink.500">
              Visit our licensing page
            </Link>
            &nbsp;to view detailed legislation or liquor licences for your state or territory.
          </Text>

          <Text fontSize="xs" color="blackAlpha.700">
            Thirsty Camel bottleshops supports the Responsible Service of Alcohol. Visit our
            licensing page to view detailed legislation or liquor licences for your state or
            territory. <b>Victoria:</b> Warning - Under the Liquor Control Reform Act 1998 it is an
            offence: To supply alcohol to a person under the age of 18 years (Penalty exceeds
            $19,000), For a person under the age of 18 years to purchase or receive liquor (Penalty
            exceeds $800). <b>New South Wales:</b> Under the Liquor Act 2007, It is against the law
            to sell or supply alcohol to, or to obtain alcohol on behalf of, a person under the age
            of 18 years. <b>Queensland:</b> Under the Liquor Act 1992, it is an offence to supply
            liquor to a person under the age of 18 years. <b>Australian Capital Territory:</b> Under
            the Liquor Act 2010. A person must not sell or supply liquor to a person under 18 years
            old on premises where the sale or supply of liquor is authorised or in a public place.
            Maximum Penalty $5500. <b>South Australia:</b> Under Liquor Licensing Act 1997, Liquor
            must NOT be supplied to persons under 18. <b>Western Australia:</b> WARNING. Under the
            Liquor Control Act 1988, it is an offence: to sell or supply liquor to a person under
            the age of 18 years on licensed or regulated premises; or for a person under the age of
            18 years to purchase, or attempt to purchase, liquor on licensed or regulated premises.
            Thirsty Camel supports the Responsible Service of Alcohol.
            <b>Tasmania:</b> Under the Liquor Licensing Act 1990 it is an offence: for liquor to be
            delivered to a person under the age of 18 years. Penalty: Fine not exceeding 20 penalty
            units. For a person under the age of 18 years to purchase liquor. Penalty, Fine not
            exceeding 10 penalty units.
          </Text>
        </Stack>
      </Container>
    </Flex>
  )
})

export default MainFooter
