import React from 'react'
import { Alert, AlertProps, Text } from '@thirstycamel/ui'

import { getNiceErrorMessage } from '../../utils/getNiceErrorMessage'

export interface FormErrorProps extends AlertProps {
  errors: any
  overrides?: { [key: number]: string }
}

export const FormError = ({ errors, overrides, ...restProps }: FormErrorProps) => {
  const _errors = getNiceErrorMessage(errors, overrides)

  if (!_errors) return null
  if (Array.isArray(_errors) && _errors.length === 0) return null

  return (
    <>
      {(Array.isArray(_errors) ? _errors : [_errors]).map(error => (
        <Alert status="error" {...restProps}>
          <Text>
            <b>Error:</b>&nbsp;{String(error)}
          </Text>
        </Alert>
      ))}
    </>
  )
}

export default FormError
