/* Ensure for each group in the bundle, there is an adequate amount items in the cart which
 * belong to that bundle. */
export const doesCartHaveIncompleteBundle = cart =>
  cart?.cartItems.some(cartItem => {
    return cartItem.product?.bundle?.groups?.some(
      group =>
        group.quantity >
        cart.cartItems.filter(
          item => item.productBundleGroup?.id === group.id && cartItem?.id === item.parent?.id,
        ).length,
    )
  })

export default doesCartHaveIncompleteBundle
